(function (H) { H.className = H.className.replace(/\bno-js\b/, 'js') })(document.documentElement);

const animate = element => (
  element.classList.add('in-viewport')
);

const isAnimated = element => (
  element.classList.contains('in-viewport')
);

// get only these elements,
// which are not animated yet
const scrollEffectTargets = [].filter.call(
  document.querySelectorAll('[data-scroll-target]'),
  element => !isAnimated(element, 'in-viewport'),
);


if ('IntersectionObserver' in window) {
  // IntersectionObserver Supported
  let config = {
    root: null,
    rootMargin: '0px',
    threshold: 0.2
  };

  function onChange(changes, observer) {
    changes.forEach(change => {
      if (change.intersectionRatio > 0) {
        // Stop watching and load the image
        animate(change.target);
        observer.unobserve(change.target);
      }
    });
  }

  let observer = new IntersectionObserver(onChange, config);
  scrollEffectTargets.forEach(target => observer.observe(target));
}

var TextPartsAnimator = function () {
  var that = this;
  this.elements = $(".text-parts-animator");

  this.elements.each(function (index, el) {
    $(el).css('opacity', 1);
    that.addSpan($(this));
  });

  $(window).on("resize scroll", function () {
    that.checkVisible();
    that.checkAnimation();
  });

  $(document).ready(function () {
    that.checkVisible();
    that.checkAnimation();
  });
};
